<template>
  <w-card class="mt10">
    <template #title>
      <w-toolbar :class="`${constants.colors.food}--bg`">
        <w-icon class="mr2" md>{{constants.icons.food}}</w-icon>
        <span>Food Intake Details</span>
        <div class="spacer"></div>
        <w-icon xl class="ml2 md btn" @click="showDialog=true">mdi mdi-pencil-plus-outline</w-icon>
        <w-icon class="ml2 md btn" @click="emit('navigateTo','/')">{{constants.icons.back}}</w-icon>
      </w-toolbar>
    </template>
    <ExpendingTableComponent
        :value="data"
        :aggregate-func="aggregateRecords"
        :aggregation-headers="aggregated_table_headers"
        :headers="raw_table_headers"
        :filter-by="filterRecordsByDate"
        :showAddButton="false"
        @delete="onDelete">
    </ExpendingTableComponent>

    <form-dialog-component
        title="New Food"
        :icon = "constants.icons.food"
        :color = "constants.colors.food"
        :show="showDialog">
      <FoodForm @submit="onFoodFormSubmit" @cancel="showDialog=false"></FoodForm>
    </form-dialog-component>
  </w-card>
</template>
<script setup>

//TODO add a raw level slot for custom icon instead of willingness number
// 1 = mdi mdi-emoticon-angry-outline
// 2 = mdi mdi-emoticon-sad-outline
// 3 = mdi mdi-emoticon-neutral-outline
// 4 = mdi mdi-emoticon-happy-outline
// 5 = mdi mdi-emoticon-excited-outline
import { constants } from "@/shared/constants";
import {  useFoodStore } from "@/stores/food.store";
import ExpendingTableComponent from "@/components/ExpendingTableComponent.vue";
import {storeToRefs} from "pinia";
import FoodForm from "@/components/FoodForm.vue";
import FormDialogComponent from "@/components/FormDialogComponent.vue";
import {ref, defineEmits} from "vue";

const emit = defineEmits(['navigateTo'])
const showDialog = ref(false)
const foodStore = useFoodStore()

const { data } = storeToRefs(foodStore)

const aggregated_table_headers = [
  {label: 'Date', key: 'date'},
  {label: 'Total intake', key: 'totalAmount'},
  {label: 'Average meal intake', key: 'averageAmount'},
  {label: 'Number of meals', key: 'numberOfMeals'},
]
const raw_table_headers =[
  {label: 'Time', key: 'time'},
  {label: 'Amount', key: 'amount'},
  {label: 'Willingness', key: 'willingness'},
  {label: 'Emissions', key: 'emissions'}
]

function aggregateRecords(data){
  return data.reduce((acc, current) => {

    const curDate = acc.find(r => r.date === current.date)
    if(curDate) {
      curDate.totalAmount += current.amount;
      curDate.numberOfMeals += 1;
      curDate.averageAmount = curDate.totalAmount / curDate.numberOfMeals;
    } else {
      acc.push({
        date: current.date,
        totalAmount: current.amount,
        averageAmount: current.amount,
        numberOfMeals: 1,
      });
    }
    return acc;
  },[])
}
function filterRecordsByDate(records, item) {
  if(records){
    return records.filter(r => r.date === item.date).map(r => {
      return {
        ...r,
        control: 'control'
      }
    }).sort((a, b) => {
      return new Date(`${b.date} ${b.time}`).getTime() - new Date(`${a.date} ${a.time}`).getTime()
    });
  } else {
    return []
  }
}

function onFoodFormSubmit(value) {
  foodStore.addRecord(value)
  showDialog.value = false
  console.log('Food Form Submitted:', value)
}

function onDelete(item) {
  foodStore.deleteRecord(item)
  console.log('Food Record Deleted:', item)
}

</script>