<template>
  <div class="login-page">
    <div class="left-side">
      <div class="logo">
        <nutrino-logo/>
      </div>
      <div class="details">
        <h1>Welcome to Baby Nutrino</h1>
        <p>Your trusted partner in baby care.</p>
      </div>
    </div>
    <div class="right-side">
      <w-card>
        <w-progress v-if="loading" class="ma1" circle></w-progress>
        <w-form v-if="!loading" @submit="login">
          <div class="login">
            <h2>Login</h2>
            <w-notification
                timeout="2000"
                shadow
                light
                no-border
                tile
                warning
                xl
                round
                :[position[0]]="true"
                :[position[1]]="true"
                v-if="loginError"
                type="error"
                @close="loginError=''">{{loginError}}</w-notification>
            <w-input label="Email" type="email" v-model="email" autocomplete="username" required></w-input>
            <w-input label="Password" type="password" v-model="password" autocomplete="current-password" required></w-input>
            <w-button type="submit" class="btn mt2">Login</w-button>
          </div>
          <w-divider/>
          <div class="social-login">
            <w-button class="btn google" @click="loginWithGoogle">Login with Google</w-button>
            <w-button class="btn facebook" @click="loginWithFacebook">Login with Facebook</w-button>
            <w-button class="btn apple" @click="loginWithApple">Login with Apple</w-button>
          </div>
          <p class="signup">Don't have an account? <a href="/signup">Sign up</a></p>
        </w-form>
      </w-card>
      <footer class="footer">
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/terms-of-use">Terms of Use</a>
        <a href="/contact">Contact Us</a>
      </footer>
    </div>
  </div>
</template>

<script setup>
import NutrinoLogo from "@/components/NutrinoLogo.vue";
import { useUserStore } from "@/stores/user.store";
import {ref,defineEmits} from "vue";
import {storeToRefs} from "pinia";

const $emit = defineEmits(['navigateTo']);
const email = ref("");
const password = ref("");
const loginError = ref("");
const position = ref(['top', 'center'])

const userStore = useUserStore();

const { loading } = storeToRefs(userStore);

async function login(event) {
  event.preventDefault();
  loginError.value = ''
  const loginResponse = await userStore.login(email.value,password.value);
  if(!loginResponse){
    loginError.value = 'Invalid email or password'
  } else {
    $emit('navigateTo', '/');
  }

}
function loginWithGoogle() {
      // Handle Google login logic
    }
function loginWithFacebook() {
      // Handle Facebook login logic
    }
function loginWithApple() {
      // Handle Apple login logic
    }
</script>

<style scoped>
.login-page {
  display: flex;
  height: 100vh;
  background: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
}

.left-side, .right-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.left-side {
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo img {
  max-width: 200px;
}

.details h1 {
  margin-top: 1rem;
  font-size: 2.5rem;
  color: #333;
}

.details p {
  font-size: 1.2rem;
  color: #666;
}

.right-side {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

form {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}




.btn {
  width: 100%;
  padding: 0.75rem;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #ff6b4a;
}

.btn.google {
  background-color: #db4437;
}

.btn.facebook {
  background-color: #3b5998;
}

.btn.apple {
  background-color: #000;
}

.social-login {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1.5rem;
}

.signup {
  margin-top: 1.5rem;
  text-align: center;
}

.signup a {
  text-decoration: none;
}

.signup a:hover {
  text-decoration: underline;
}

.footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 1rem;
}

.footer a {
  margin: 0 1rem;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
</style>
