<template>
  <div>
    <h2>Yahav</h2>
    <b-form @submit.prevent="submitForm">
      <b-form-group label="Gender">
        <b-form-select v-model="general.gender">
          <option value="M">male</option>
          <option value="F">female</option>
        </b-form-select>
      </b-form-group>

      <b-form-group label="Date of Birth">
        <b-form-input type="date" v-model="general.dateOfBirth"></b-form-input>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox v-model="general.bornPrematurely">Born Prematurely</b-form-checkbox>
      </b-form-group>

      <!-- Conditionally display fields if born prematurely -->
      <b-form-group v-if="general.bornPrematurely" label="Week Number">
        <b-form-input type="number" min="20" max="42" v-model="general.weekNumber"></b-form-input>
      </b-form-group>

      <b-form-group v-if="general.bornPrematurely" label="+">
        <b-form-input type="number" min="0" max="6" v-model="general.day"></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">Submit</b-button>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import {BFormGroup} from "bootstrap-vue-3";

export default {
  components: {BFormGroup},
  data() {
    return {
      general: {
        gender: 'M',
        dateOfBirth: '',
        bornPrematurely: false,
        weekNumber: '',
        day: ''
      }
    };
  },
  methods: {
    submitForm() {
      axios.post('/api/general', this.general)
          .then(() => {
            alert('General information recorded successfully!');
            this.resetForm();
          })
          .catch(() => {
            alert('Error recording general information.');
          });
    },
    resetForm() {
      this.general = {
        dateOfBirth: '',
        bornPrematurely: false,
        weekNumber: '',
        day: ''
      };
    }
  }
};
</script>

<style scoped>
h2 {
  margin-top: 20px;
}
</style>
