<template>
  <w-card class="mt10">
    <template #title>
      <w-toolbar :class="`${constants.colors.medicine}--bg`">
        <w-icon class="mr2" md>{{constants.icons.medicine}}</w-icon>
        <span>Medicines Details</span>
        <div class="spacer"></div>
        <w-icon xl class="ml2 md btn" @click="showDialog=true">mdi mdi-pencil-plus-outline</w-icon>
        <w-icon class="ml2 md btn" @click="emit('navigateTo','/')">{{constants.icons.back}}</w-icon>
      </w-toolbar>
    </template>
    <ExpendingTableComponent
        :value="data"
        :aggregate-func="aggregateRecords"
        :aggregation-headers="aggregated_table_headers"
        :headers="raw_table_headers"
        :filter-by="filterRecordsByDate"
        :showAddButton="false"
        @delete="onDelete">
    </ExpendingTableComponent>

    <form-dialog-component
        title="New Medicine"
        :icon = "constants.icons.medicine"
        :color = "constants.colors.medicine"
        :show="showDialog">
      <MedicineForm @submit="onFormSubmit" @cancel="showDialog=false"></MedicineForm>
    </form-dialog-component>
  </w-card>
</template>
<script setup>


import { constants } from "@/shared/constants";
import {  useMedicationStore } from "@/stores/medication.store";
import ExpendingTableComponent from "@/components/ExpendingTableComponent.vue";
import {storeToRefs} from "pinia";
import FormDialogComponent from "@/components/FormDialogComponent.vue";
import {ref, defineEmits} from "vue";
import MedicineForm from "@/components/MedicineForm.vue";
import {kebabCaseToTitleCase} from "@/shared/utils";

const emit = defineEmits(['navigateTo'])
const showDialog = ref(false)
const medicationStore = useMedicationStore()

const { data } = storeToRefs(medicationStore)

const aggregated_table_headers = [
  {label: 'Date', key: 'date'},
  {label: 'Given', key: 'given'}
]
const raw_table_headers =[
  {label: 'Time', key: 'time'},
  {label: 'Medication', key: 'name'},
]



function aggregateRecords(data){
  console.log(data)
  return data.reduce((acc, current) => {

    const curDate = acc.find(r => r.date === current.date)
    if(curDate) {
      const formatedMedicine = kebabCaseToTitleCase(current.name)
      if(curDate.given.includes(formatedMedicine)) return acc;
      curDate.given += `, ${kebabCaseToTitleCase(current.name)}`
    } else {
      acc.push({
        date: current.date,
        given: kebabCaseToTitleCase(current.name)
      });
    }
    return acc;
  },[])
}
function filterRecordsByDate(records, item) {
  if(records){
    return records.filter(r => r.date === item.date).map(r => {
      return {
        ...r,
        name: kebabCaseToTitleCase(r.name),
        control: 'control'
      }
    }).sort((a, b) => {
      return new Date(`${b.date} ${b.time}`).getTime() - new Date(`${a.date} ${a.time}`).getTime()
    });
  } else {
    return []
  }
}

function onFormSubmit(value) {
  medicationStore.addRecords(value)
  showDialog.value = false
  console.log('Medicine Form Submitted:', value)
}

function onDelete(item) {
  medicationStore.deleteRecord(item)
  console.log('Medicine Record Deleted:', item)
}

</script>