<template>
  <div :class="tileClass">
    <w-card  tile>
      <template #title>
        <w-toolbar :color="titleColor">
          <div class="title5">{{ title }}</div>
          <div class="spacer"></div>
          <span class="ml2">
            <w-icon xl class="btn ma1" @click="$emit('add_new')">mdi mdi-pencil-plus-outline</w-icon>
          </span>
          <span class="ml2">
            <w-icon class="btn  ma1" xl @click="$emit('navigate')">mdi mdi-export</w-icon>
          </span>
        </w-toolbar>
      </template>
      <div>
      </div>
      <div class="title1">
        <w-icon class="btn" size="2.5em" color="primary" @click="$emit('navigate')">{{ icon }}</w-icon>
      </div>
      <div class="pa1">
        <w-tooltip show-on-click v-for="tag in tags" top v-bind:key="tag">
          <template #activator="{ on }">
            <w-tag md color="indigo" v-on="on" class="ma1">
              <w-icon v-if="tag.icon" class="mr2" md>
                {{ tag.icon }}
              </w-icon>
              <div>
                {{ tag.label }}
              </div>
            </w-tag>
          </template>
          {{ tag.tooltip }}
        </w-tooltip>
      </div>
      <w-flex>
      </w-flex>
    </w-card>
  </div>
</template>
<script setup>
import {defineProps, defineEmits, computed} from 'vue'

defineEmits(['add_new', 'navigate'])



const props = defineProps({
  title: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    required: true
  },
  size: {
    type: Number,
    required: true
  },
  color: {
    type: String,
    required: true
  },
  tags: {
    type: Array,
    required: true
  }

})

const titleColor = computed(() => {
  return `${props.color}--bg`
})

const tileClass = computed(() => {
  return `xs${props.size} pa1 fill-height`
})

</script>