<template>
  <w-table
      :headers="aggregationHeaders"
      :items="aggregatedData"
      fixed-headers
      expandable-rows>
    <template #item-cell="{  label }">
      <div v-if="!label || label==='none'">
        0
      </div>
      <div v-else>
        {{ label }}
      </div>
    </template>
    <template #row-expansion="{ item }">
      <w-table
          class="info-dark2"
          :headers="customHeaders"
          :items="filterRecords(item)">
        <template #header-label="{ label }">
          <div v-if="label==='control'">
          </div>
        </template>
        <template #item-cell="{  header, label, item }">
          <div v-if="header.label==='control'">
            <w-button  bg-color="secondary" icon="mdi mdi-delete" xs @click="deleteRecord(item)"></w-button>
          </div>
          <div v-else-if="!label || label==='none'">
            -
          </div>
          <div v-else>
            {{ label }}
          </div>
        </template>
      </w-table>
    </template>


    <template #footer v-if="showAddButton">
      <div  class="text-right">
        <w-button class="ma1"  @click="addNew">
          <w-icon class="mr1">wi-plus</w-icon>
          Add New
        </w-button>
      </div>
    </template>
  </w-table>
</template>
<script setup>

import { defineProps, defineEmits, computed} from 'vue'

// const model = defineModel()
const emit = defineEmits(['add', 'delete'])
const props = defineProps({
  aggregateFunc: {
    type: Function,
    required: true,
  },
  filterBy: {
    type: Function,
    required: true,
  },
  value: {
    type: Array,
    required: true,
  },
  headers: {
    type: Array,
    required: true,
  },
  aggregationHeaders: {
    type: Array,
    required: true,
  },
  showAddButton: {
    type: Boolean,
    default: true
  }
})

function addNew(){
  emit('add')
}
function deleteRecord(item){
  emit('delete', item)
}
function filterRecords(item){
  return props.filterBy(props.value.map(r=>({
    ...r,
    control: 'control'
  })), item)
}

const customHeaders = computed(()=>{
  return [...props.headers, {label: 'control', key: 'control'}]
})

const aggregatedData = computed(()=> {
  return props.aggregateFunc(props.value);
})
</script>