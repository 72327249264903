<template>
  <div class="text-center">
    <div>
      <p class="size--sm">
        Baby Nutrino is designed to help you monitor your baby's feeding,
        diaper changes, medications, sleep, and growth — giving you the
        insights you need to support your little one’s development.
      </p>
      <p  class="size--sm">
        To get started, we’ll ask a few questions to personalize your experience.
      </p>
    </div>
    <div>
      <w-form v-model="valid" @before-validate="validate" @submit="submitForm">
        <div class="size--sm mt3 mb1">What Is The Baby's Name?</div>
        <w-input color="black" type="text" v-model="babyName" :validators="validators"></w-input>
        <div class="size--sm mt3 mb1">What Is The Baby's Gender?</div>
        <w-radios v-model="babyGender" label-color="black" :items="[
            { label: 'Boy', value: 'M' },
            { label: 'Girl', value: 'F' },
          ]" inline :validators="validators"></w-radios>
        <div class="size--sm mt3 mb1">Congratulations, When was The Baby Born?</div>
        <w-input color="black" type="date" class="align-content-center" v-model="babyBirthDate" :validators="validators"></w-input>
        <div class="size--sm mt3 mb1">What Was The Baby's Weight At Birth?</div>
        <w-input color="black" type="number" v-model="babyWeight" :validators="validators"></w-input>
        <div class="size--sm mt3 mb1">Was The Baby Born Prematurely?</div>
        <w-radios v-model="babyPremature" label-color="black" :items="[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]" inline :validators="validators"></w-radios>
        <div v-if="babyPremature">
          <div class="size--sm mt3 mb1">What Was The Expected Birth Date?</div>
          <w-input color="black" type="date"  class="align-content-center" v-model="babyExpectedBirthDate" :validators="prematureValidators"></w-input>
        </div>
        <w-divider dark class="mt3"></w-divider>
        <w-button type="submit" class="mt-5 fill-width shadow round">Get Started</w-button>
      </w-form>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';

const $emits = defineEmits(['submit']);

const babyName = ref(null);
const babyBirthDate = ref(null);
const babyPremature = ref(null);
const babyExpectedBirthDate = ref(null);
const babyWeight = ref(null);
const babyGender = ref(null);
const valid = ref(null)

const validators = [(value) => !!value  || 'This field is required' ]
const prematureValidators = [(value) => !!value  || 'This field is required' , (value) => {
  const premature = calculateWeeksAndDays(new Date(babyBirthDate.value), new Date(value));
  let weeks = premature.days ? 39 - premature.weeks : 40 - premature.weeks;
  if(weeks < 20) return 'Expected birth date should be at most 20 weeks after the birth date';
  if (weeks > 38) return 'Expected birth date should be at lease 2 weeks after birth date';
  return true;
}]

function validate() {
  const isValid = !babyPremature.value
      ? !!babyName.value && !!babyBirthDate.value && babyPremature.value!=null && !!babyWeight.value && !!babyGender.value
      : !!babyName.value && !!babyBirthDate.value && !!babyPremature.value && !!babyWeight.value && !!babyGender.value && !!babyExpectedBirthDate.value;
  valid.value = isValid
  return isValid;
}

function submitForm(event) {
  if (!valid.value) return;
  event.preventDefault();
  $emits('submit', {
    name: babyName.value,
    birthDate: babyBirthDate.value,
    preterm: babyPremature.value,
    expectedBirthDate: babyExpectedBirthDate.value,
    birthWeight: babyWeight.value,
    gender: babyGender.value
  });
}

function calculateWeeksAndDays(fromDate, toDate) {
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
  const diffInTime = toDate - fromDate;
  const diffInDays = Math.floor(diffInTime / oneDay);
  const weeks = Math.floor(diffInDays / 7);
  const days = diffInDays % 7;
  return { weeks, days };
}



</script>