<template>
  <div class="signup-page">
    <div class="left-side">
      <div class="logo">
        <nutrino-logo/>
      </div>
      <div class="details">
        <h1>Welcome to Baby Nutrino</h1>
        <p>Your trusted partner in baby care.</p>
      </div>
    </div>
    <div class="right-side">
      <w-card>
        <w-progress v-if="loading" class="ma1" circle></w-progress>
        <w-form @submit="signup" v-model="valid" v-if="!loading" no-keyup-validation>
          <div class="login">
            <h2>Signup</h2>
            <w-input label="Email"
                     type="email"
                     :validators="[validators.required]"
                     v-model="email" required></w-input>
            <w-input label="Password"
                     :type="isPassword ? 'password' : 'text'"
                     :inner-icon-right="isPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"
                     @click:inner-icon-right="isPassword = !isPassword"
                     :validators="[validators.required, validators.password]"
                     v-model="password" required></w-input>
            <w-button type="submit" class="btn mt2" >Signup</w-button>
          </div>
          <w-divider/>
          <div class="social-signup">
            <w-button class="btn google" @click="signupWithGoogle" v-if="google">Signup with Google</w-button>
            <w-button class="btn facebook" @click="signupWithFacebook" v-if="facebook">Signup with Facebook</w-button>
            <w-button class="btn apple" @click="signupWithApple" v-if="apple">Signup with Apple</w-button>
          </div>
          <p class="signup">Already have an account? <a href="/login">Login</a></p>
          <w-notification
              timeout="2000"
              absolute
              shadow
              light
              no-border
              tile
              warning
              md
              transition="none"
              :[position[0]]="true"
              :[position[1]]="true"
              :v-model="signupError"
              v-if="signupError"
              type="error">{{signupError}}</w-notification>
        </w-form>
      </w-card>
      <footer class="footer">
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/terms-of-use">Terms of Use</a>
        <a href="/contact">Contact Us</a>
      </footer>
    </div>
  </div>
</template>

<script setup>
import NutrinoLogo from "@/components/NutrinoLogo.vue";
import { useUserStore } from "@/stores/user.store";
import {ref, defineEmits} from "vue";
import {storeToRefs} from "pinia";

const emit = defineEmits(['navigateTo'])
const valid = ref(false)
const email = ref("");
const password = ref("");
const signupError = ref();
const isPassword = ref(true);
const validators = {
  required: (value) => !!value || 'Required.',
  //password: (input) => /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,}).*$/.test(input) || 'Password must contain at least 8 characters, one uppercase letter, one number and one special character.'
  password: (input) => {
    let errors = [];
    if (!/(?=.*[A-Z])/.test(input)) {
      errors.push("one uppercase letter");
    }
    if (!/(?=.*\d)/.test(input)) {
      errors.push("one number");
    }
    if (!/(?=.*[!@#$%^&*])/.test(input)) {
      errors.push("one special character");
    }
    if (!/(?=.{8,})/.test(input)) {
      errors.push("8 characters long");
    }

    if (errors.length > 0) {
      return `Password is missing at least ${errors.join(", ")}.`;
    }
    return true;
  }
}
const google = ref(false)
const facebook = ref(false)
const apple = ref(false)

const position = ref(['bottom', 'center'])

const userStore = useUserStore();

const { loading } = storeToRefs(userStore);

async function signup() {
  if(!valid.value) return
  signupError.value = ''
  const signupResponse = await userStore.signup(email.value,password.value);
  if(!signupResponse.created){
    signupError.value = signupResponse.message
  } else {
    emit('navigateTo', 'login')
  }
}
function signupWithGoogle() {
      // Handle Google signup logic
    }
function signupWithFacebook() {
      // Handle Facebook signup logic
    }
function signupWithApple() {
      // Handle Apple signup logic
    }
</script>

<style scoped>
.signup-page {
  display: flex;
  height: 100vh;
  background: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
}

.left-side, .right-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.left-side {
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo img {
  max-width: 200px;
}

.details h1 {
  margin-top: 1rem;
  font-size: 2.5rem;
  color: #333;
}

.details p {
  font-size: 1.2rem;
  color: #666;
}

.right-side {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

form {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}




.btn {
  width: 100%;
  padding: 0.75rem;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #ff6b4a;
}

.btn.google {
  background-color: #db4437;
}

.btn.facebook {
  background-color: #3b5998;
}

.btn.apple {
  background-color: #000;
}

.social-signup {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1.5rem;
}

.signup {
  margin-top: 1.5rem;
  text-align: center;
}

.signup a {
  text-decoration: none;
}

.signup a:hover {
  text-decoration: underline;
}

.footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 1rem;
}

.footer a {
  margin: 0 1rem;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
</style>
