<template>
<div>
  <w-flex wrap class="text-center">
    <TileComponent title="Food"
                   :tags="foodTags"
                   :color= "constants.colors.food"
                   :size="6"
                   :icon="constants.icons.food"
                   @add_new="activeDialog='food'"
                   @navigate="goToFoodPage">
    </TileComponent>
    <TileComponent title="Diapers"
                   :tags="diapersTags"
                   :color= "constants.colors.diaper"
                   :size="6"
                   :icon="constants.icons.diaper"
                   @add_new="activeDialog='diaper'"
                   @navigate="goToDiaperPage">
    </TileComponent>
    <TileComponent title="Medication"
                   :tags="medicationTags"
                   :color= "constants.colors.medicine"
                   :size="6"
                   :icon="constants.icons.medicine"
                   @add_new="activeDialog='medicine'"
                   @navigate="goToMedicinePage">
    </TileComponent>
    <TileComponent title="Measurements"
                   :tags="measurementTags"
                   :color= "constants.colors.measurements"
                   :size="6"
                   :icon="constants.icons.measurements"
                   @add_new="activeDialog='measurements'"
                   @navigate="goToMeasurementsPage">
    </TileComponent>
  </w-flex>
</div>
  <form-dialog-component
      title="New Food"
      :icon = "constants.icons.food"
      :color = "constants.colors.food"
      :show="activeDialog==='food'">
    <FoodForm @submit="onFoodFormSubmit" @cancel="onDialogCancel"></FoodForm>
  </form-dialog-component>
  <form-dialog-component
      title="New Diaper"
      :icon = "constants.icons.diaper"
      :color = "constants.colors.diaper"
      :show="activeDialog==='diaper'">
    <DiaperForm @submit="onDiaperFormSubmit" @cancel="onDialogCancel"></DiaperForm>
  </form-dialog-component>
  <form-dialog-component
      title="New Medication"
      :icon = "constants.icons.medicine"
      :color = "constants.colors.medicine"
      :show="activeDialog==='medicine'">
    <MedicineForm @submit="onMedicineFormSubmit" @cancel="onDialogCancel"></MedicineForm>
  </form-dialog-component>
  <form-dialog-component
      title="New Measurements"
      :icon = "constants.icons.measurements"
      :color = "constants.colors.measurements"
      :show="activeDialog==='measurements'">
    <MeasurementForm @submit="onMeasurementsFormSubmit" @cancel="onDialogCancel"></MeasurementForm>
  </form-dialog-component>
  <form-dialog-component
      title="Welcome to Baby Nutrino"
      icon = "mdi mdi-baby"
      color = "info"
      :show="!babyWasCreated">
    <WelcomeForm @submit="onWelcomeFormSubmit"></WelcomeForm>
  </form-dialog-component>
  {{babyWasCreated}}
</template>
<script setup>
import { constants } from "@/shared/constants";
import {defineEmits,computed, ref} from 'vue';
import TileComponent from "@/components/TileComponent.vue";
import DiaperForm from "@/components/DiaperForm.vue";
import FormDialogComponent from "@/components/FormDialogComponent.vue";
import FoodForm from "@/components/FoodForm.vue";
import MedicineForm from "@/components/MedicineForm.vue";
import MeasurementForm from "@/components/MeasurementForm.vue";
import {useFoodStore} from "@/stores/food.store";
import {useDiaperStore} from "@/stores/diaper.store";
import {useMedicationStore} from "@/stores/medication.store";
import {useWeightStore} from "@/stores/weight.store";
import {useHeightStore} from "@/stores/height.store";
import {useHeadSizeStore} from "@/stores/head-size.store";
import { kebabCaseToTitleCase} from "@/shared/utils";
import {storeToRefs} from "pinia";
import {useBabyStore} from "@/stores/baby.store";
import WelcomeForm from "@/components/WelcomeForm.vue";

const emits = defineEmits(['navigateTo'])
const babyStore = useBabyStore()
const foodStore = useFoodStore()
const weightStore = useWeightStore()
const heightStore = useHeightStore()
const headSizeStore = useHeadSizeStore()
const diaperStore = useDiaperStore()
const medicationStore = useMedicationStore()
const activeDialog = ref(null)

const { babyWasCreated } = storeToRefs(babyStore)
const { lastRecord: foodLastRecord } = storeToRefs(foodStore)
const { lastRecord: diaperLastRecord } = storeToRefs(diaperStore)
const { todayRecords: medicationTodayRecords } = storeToRefs(medicationStore)
const { lastRecord: weightLastRecord } = storeToRefs(weightStore)
const { lastRecord: heightLastRecord } = storeToRefs(heightStore)
const { lastRecord: headLastRecord } = storeToRefs(headSizeStore)

function onDiaperFormSubmit(value) {
  diaperStore.addRecord(value)
  activeDialog.value = false
  console.log('Diaper Form Submitted:', value)
}

function onFoodFormSubmit(value) {
  foodStore.addRecord(value)
  activeDialog.value = false
  console.log('Food Form Submitted:', value)
}

function onMedicineFormSubmit(value) {
  medicationStore.addRecords(value)
  activeDialog.value = false
  console.log('Medicine Form Submitted:', value)
}

function onMeasurementsFormSubmit(value) {
  console.log(value)
  if(value.weight) {
    weightStore.addRecord({
      date: value.date,
      time: value.time,
      weight: value.weight
    })
  }
  if(value.height) {
    heightStore.addRecord({
      date: value.date,
      time: value.time,
      height: value.height
    })
  }
  if(value.headCircumference) {
    headSizeStore.addRecord({
      date: value.date,
      time: value.time,
      headCircumference: value.headCircumference
    })
  }
  activeDialog.value = false
  console.log('Measurements Form Submitted:', value)
}

function onWelcomeFormSubmit(value) {
  babyStore.create(value)
  console.log('Welcome Form Submitted:', value)
}


function onDialogCancel() {
  activeDialog.value = false
  console.log('Diaper Form Cancelled')
}
function getStringOfTimePassedSinceDatetime(datetime) {
  const now = new Date().getTime()
  const diff = now - datetime
  const minutes = Math.floor(diff / 60000)
  if(minutes < 60) {
    return minutes + ' minutes ago'
  }
  const hours = Math.floor(minutes / 60)
  if(hours < 24) {
    return hours + ' hours ago'
  }
  const days = Math.floor(hours / 24)
  return days + ' days ago'
}


const foodTags = computed(() => {
  if(!foodLastRecord.value.id) {
    return [{
      label: '-',
    }]
  }
  return [{
    icon: 'mdi mdi-baby-bottle-outline',
    label: getStringOfTimePassedSinceDatetime(new Date(`${foodLastRecord.value.date}T${foodLastRecord.value.time}:00`)),
    tooltip: `${foodLastRecord.value.date} ${foodLastRecord.value.time}`
  }]
})

const diapersTags = computed(() => {

  if (!diaperLastRecord.value.id) {
    return [{
      label: '-',
    }]
  }
  const icon = diaperLastRecord.content === 'wet' ? 'mdi mdi-water' : diaperLastRecord.content === 'poop' ? 'mdi mdi-poop' : 'mdi mdi-alert-circle-outline'
  return [{
    icon,
    label: getStringOfTimePassedSinceDatetime(new Date(`${diaperLastRecord.value.date}T${diaperLastRecord.value.time}:00`)),
    tooltip: `${diaperLastRecord.value.date} ${diaperLastRecord.value.time}`
  }]
})

const medicationTags = computed(() => {

  if(medicationTodayRecords.value.length === 0) {
    return [{
      label: '-',
    }]
  }
  return medicationTodayRecords.value.map(record => ({
    icon: constants.icons.medical_bag,
    label: kebabCaseToTitleCase(record.name),
    tooltip: getStringOfTimePassedSinceDatetime(new Date(`${record.date}T${record.time}:00`)),
  }))
})

const measurementTags = computed(() => {
  return [{
    icon: 'mdi mdi-weight-kilogram',//mdi-scale-bathroom
    label: weightLastRecord.value.weight,
    tooltip: getStringOfTimePassedSinceDatetime(new Date(`${weightLastRecord.value.date}T${weightLastRecord.value.time}:00`)),
  },{
    icon: 'mdi mdi-ruler',
    label: heightLastRecord.value.height,
    tooltip: getStringOfTimePassedSinceDatetime(new Date(`${heightLastRecord.value.date}T${heightLastRecord.value.time}:00`)),
  },{
    icon: 'mdi mdi-head-outline',
    label: headLastRecord.value.headCircumference,
    tooltip: getStringOfTimePassedSinceDatetime(new Date(`${headLastRecord.value.date}T${headLastRecord.value.time}:00`)),
  }]
})


function goToFoodPage() {
  emits('navigateTo', 'food')
}

function goToDiaperPage() {
  emits('navigateTo', 'diaper')
}

function goToMedicinePage() {
  emits('navigateTo', 'medication')
}

function goToMeasurementsPage() {
  emits('navigateTo', 'measurements')
}



</script>
<style>

</style>
