<template>
  <WeightGraph :by-value="(item)=>item.weight" :by-label="(item)=>item.date" label="Kg" v-model="aggregatedData">
  </WeightGraph>
</template>
<script setup>
import { useWeightStore } from "@/stores/weight.store";
import {storeToRefs} from "pinia";
import {computed} from "vue";
import WeightGraph from "@/components/WeightGraphComponent.vue";

const weightStore = useWeightStore()

const { data } = storeToRefs(weightStore)

const aggregatedData = computed(()=>{
  return data.value.reduce((acc, item)=> {
    const existing = acc.find(a=>a.date === item.date)
    if(existing){
      existing.weight = item.weight > existing.weight ? item.weight : existing.weight
    } else {
      acc.push({
        date: item.date,
        weight: item.weight
      })
    }
    return acc
  },[]).sort((a,b)=>new Date(a.date)-new Date(b.date))
})
</script>