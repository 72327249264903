<template>
  <div v-if="chartData && chartData.labels && chartData.labels.length > 0">
    <line-chart :data="chartData" :options="chartOptions" />
  </div>
  <div v-else>
    <p>No {{ label }} data available for the chart.</p>
  </div>
</template>

<script>
import '@mdi/font/css/materialdesignicons.min.css'
import {Line as LineChart} from "vue-chartjs";

export default {
  components: {LineChart},
  computed: {
    chartData() {
      return {
        labels: this.modelValue.map(this.byLabel),
        datasets: [
          {
            label: this.label,
            data: this.modelValue.map(this.byValue),
            fill: false,
            borderColor: 'blue',
            tension: 0.5
          }
        ]
      }
    }
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    byLabel: {
      type: Function,
      required: true
    },
    byValue: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false

    }
  })
}
</script>