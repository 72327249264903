<template>
  <div>
    <div class="text-left primary ma1">
      Select an option:
    </div>
    <w-flex>
      <div class="text-center xs2 ma1" v-for="item in items" v-bind:key="item">
        <div>
          <w-icon xl :bg-color="itemBgColor(item.value)" @click="selectItem(item.value)">{{item.icon}}</w-icon>
        </div>
        <span class="size--xs primary">
          {{item.label}}
        </span>
      </div>
    </w-flex>
  </div>
</template>
<script setup>
import {defineEmits,defineProps, ref} from 'vue'

const $emits = defineEmits(['update:modelValue'])

function itemBgColor(value) {
      return value === selected.value ?  props.selectedColor : props.itemColor
}

function selectItem(value) {
  selected.value = value
  $emits('update:modelValue', value)
}

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  items: {
    type: Array,
    required: true
  },
  selectedColor: {
    type: String,
    default: 'info-light2'
  },
  itemColor: {
    type: String,
    default: 'info-light5'
  }
})

const selected = ref(props.modelValue)


</script>