export  const POST = (url,body,options)=> {
    return fetch(url, {
        method: 'POST',
        body,
        ...(options || {}),
        headers: {
            'Content-Type': 'application/json',
            ...(options?.headers || {}),
        }
    })
}

export  const PUT = (url,body,options)=> {
    return fetch(url, {
        method: 'PUT',
        body,
        ...(options || {}),
        headers: {
            'Content-Type': 'application/json',
            ...(options?.headers || {}),
        }
    })
}

export const GET = (url,options)=> {
    return fetch(url, {
        method: 'GET',
        ...(options || {}),
        headers: {
            'Content-Type': 'application/json',
            ...(options?.headers || {}),
        }
    })
}

export const DELETE = (url,options)=> {
    return fetch(url, {
        method: 'DELETE',
        ...(options || {}),
        headers: {
            'Content-Type': 'application/json',
            ...(options?.headers || {}),
        }
    })
}