import {DELETE, GET, POST} from "@/services/base.http";

export function getRepo(key) {
    return (function (){
        async function fetchRecords(accountId){
            const response = await GET(`/api/${key}/${accountId}`)
            if(!response.ok) {
                console.error(`Error fetching ${key} records: ${response.status}`)
                return {
                    data: []
                }
            }
            const data = await response.json()
            return {
                data
            }
        }

        async function addRecord(accountId,record){
            const response = await POST(`/api/${key}/${accountId}`,JSON.stringify(record))
            if(!response.ok) {
                console.error(`Error adding ${key} record: ${response.status}`)
                return {
                    data: null
                }
            }
            const data = await response.json()
            return {
                data
            }
        }

        async function addRecords(accountId,records){
            const response = await POST(`/api/${key}/${accountId}/bulk`,JSON.stringify(records))
            if(!response.ok) {
                console.error(`Error adding ${key} records: ${response.status}`)
                return {
                    data: []
                }
            }
            const data = await response.json()
            return {
                data
            }
        }

        async function deleteRecord(accountId,record){
            const response = await DELETE(`/api/${key}/${accountId}/${record.id}`)
            if(!response.ok) {
                console.error(`Error deleting ${key} record: ${response.status}`)
                return {
                    data: null
                }
            }
            const data = await response.json()
            return {
                data
            }

        }

        return {
            fetchRecords,
            addRecord,
            addRecords,
            deleteRecord
        }
    })()
}