export const fetchNursingRecords = async () => {
    const currentStateString = localStorage.getItem('nursing-records');
    const currentState = currentStateString ? JSON.parse(currentStateString) : [];
    return Promise.resolve({
        data: currentState
    })
}

export const addNursingRecord = async (newUser) => {
    const idIndexString = localStorage.getItem('nursing-records-id-index')
    const idIndex = idIndexString ? parseInt(idIndexString)+1 : 0;
    localStorage.setItem('nursing-records-id-index', idIndex.toString());
    const currentStateString = localStorage.getItem('nursing-records');
    const currentState = currentStateString ? JSON.parse(currentStateString) : [];
    currentState.push({
        id: idIndex,
        ...newUser
    });
    localStorage.setItem('nursing-records', JSON.stringify(currentState));
    return Promise.resolve({
        data: {
            id: idIndex,
            ...newUser
        }
    })
}
export const deleteNursingRecord = async (id) => {
    const currentStateString = localStorage.getItem('nursing-records');
    let currentState = currentStateString ? JSON.parse(currentStateString) : [];
    currentState = currentState.filter(r => r.id !== id);
    localStorage.setItem('nursing-records', JSON.stringify(currentState));
}