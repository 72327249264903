import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import BootstrapVue3 from 'bootstrap-vue-3';
import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'
import {pinia} from "@/pinia";

// Create a Pinia instance

const app = createApp(App);

// Use Pinia in the app
app.use(pinia)

import router from './router';

app.use(router);
app.use(BootstrapVue3);
app.use(WaveUI, { /* Some Wave UI options */ })
app.mount('#app');
