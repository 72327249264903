<template>
  <div class="xs6 pa1">
    <w-input type="date" label-position="left" label="Date" outline v-model="date"/>
  </div>
  <div class="xs6 pa1">
    <w-input type="time" label-position="left" label="Time" outline v-model="time"/>
  </div>
  <div class="ma2">
    <w-input v-model="weight" type="number" inner-icon-left="mdi mdi-weight-kilogram" label-position="left" label="Weight"></w-input>
    <w-input v-model="height" type="number" inner-icon-left="mdi mdi-ruler" label-position="left" label="Height"></w-input>
    <w-input v-model="headSize" type="number" inner-icon-left="mdi mdi-head" label-position="left" label="Head Size"></w-input>
  </div>
  <w-divider></w-divider>
  <div class="text-right">
    <w-button class="ma1" bg-color="secondary" icon="wi-cross" @click="onCancel"></w-button>
    <w-button class="ma1" bg-color="primary" icon="wi-check" :disabled="!valid" @click="onSubmit"></w-button>
  </div>
</template>
<script setup>
import {ref, defineEmits, computed} from "vue";
import {toISOStringWithLocalTimezone} from "@/shared/to-iso-string-with-local-timezone.function";
import {extractHoursAndMinutesFromDate} from "@/shared/extract-hours-and-minutes-from-date.function";

const weight = ref(0)
const height = ref(0)
const headSize = ref(0)

const date = ref(new Date().toISOString().split('T')[0])
const time = ref(new Date().toISOString().split('T')[1].split('.')[0])

const $emit = defineEmits(['cancel', 'submit'])

const valid = computed(() => {
  return weight.value > 0 ||
  height.value > 0 ||
  headSize.value > 0
})

function onCancel() {
  $emit('cancel')
  clear()
}

function onSubmit() {
  $emit('submit', {
    date: date.value,
    time: extractHoursAndMinutesFromDate(time.value),
    weight: weight.value,
    height: height.value,
    headCircumference: headSize.value
  })
  clear()
}

function clear(){
  date.value = ref(new Date().toISOString().split('T')[0])
  time.value = ref(toISOStringWithLocalTimezone(new Date()).split('T')[1])
  weight.value = ref(0)
  height.value = ref(0)
  headSize.value = ref(0)
}
</script>