<template>
  <div class="title1 text-center ma5">
    Nursing Records
  </div>

  <ExpendingTableComponent
    v-model="rawRecords"
    :aggregate-func="dailyAggregates"
    :aggregation-headers="table.aggregated_table_headers"
    :headers="table.raw_table_headers"
    :filter-by="dailyRecords"
    @add="dialog.show = true"
    @delete="deleteItem">
  </ExpendingTableComponent>

  <div class="title3 text-center ma5">
    Intake
  </div>

  <ChartComponent :by-value="(item)=>item.totalAmount" :by-label="(item)=>item.date" label="intake" v-model="chartData">
  </ChartComponent>


  <w-dialog
      v-model="dialog.show"
      :fullscreen="dialog.fullscreen"
      :width="dialog.width"
      :persistent="dialog.persistent"
      :persistent-no-animation="dialog.persistentNoAnimation"
      :overlay-color="dialog.overlayColor"
      :overlay-opacity="dialog.overlayOpacity"
      title-class="primary-light1--bg white">
    <template #title>
      <w-icon class="mr2">mdi mdi-tune</w-icon>
      Add Nursing Record
    </template>
    <NursingRecordsForm @submit="onSubmit" @cancel="dialog.show=false"/>
  </w-dialog>

</template>

<script setup>

import NursingRecordsForm from "@/components/NursingRecordsForm.vue";
import ExpendingTableComponent from "@/components/ExpendingTableComponent.vue";
import ChartComponent from "@/components/ChartComponent.vue";

import {computed, ref} from 'vue'
import {useCounterStore} from "@/stores/count.store";
import {storeToRefs} from "pinia";

const counterStore = useCounterStore()

counterStore.fetchRecords()

const { rawRecords } = storeToRefs(counterStore)

  //   ref([
  // {
  //   id: 1,
  //   date: '2024-09-01',
  //   time: '10:00',
  //   amount: 10,
  //   willingness: 5,
  //   emissions: 'none',
  //   diaper: 'none',
  //   supplements: 'none',
  //   mealTime: 9,
  // }, {
  //   id: 2,
  //   date: '2024-09-01',
  //   time: '10:00',
  //   amount: 0,
  //   willingness: 5,
  //   emissions: 'none',
  //   diaper: 'none',
  //   supplements: 'none',
  //   mealTime: 9,
  // }, {
  //   id: 3,
  //   date: '2024-09-01',
  //   time: '13:00',
  //   amount: 20,
  //   willingness: 5,
  //   emissions: 'none',
  //   diaper: 'none',
  //   supplements: 'none',
  //   mealTime: 10,
  // }, {
  //   id: 4,
  //   date: '2024-09-01',
  //   time: '16:00',
  //   amount: 90,
  //   willingness: 5,
  //   emissions: 'none',
  //   diaper: 'none',
  //   supplements: 'none',
  //   mealTime: 10,
  // }, {
  //   id: 5,
  //   date: '2024-09-01',
  //   time: '19:00',
  //   amount: 20,
  //   willingness: 5,
  //   emissions: 'none',
  //   diaper: 'none',
  //   supplements: 'none',
  //   mealTime: 10,
  // }, {
  //   id: 6,
  //   date: '2024-09-02',
  //   time: '10:00',
  //   amount: 10,
  //   willingness: 5,
  //   emissions: 'none',
  //   diaper: 'none',
  //   supplements: 'none',
  //   mealTime: 10,
  // }, {
  //   id: 7,
  //   date: '2024-09-02',
  //   time: '13:00',
  //   amount: 20,
  //   willingness: 5,
  //   emissions: 'none',
  //   diaper: 'none',
  //   supplements: 'none',
  //   mealTime: 10,
  // }, {
  //   id: 8,
  //   date: '2024-09-02',
  //   time: '16:00',
  //   amount: 90,
  //   willingness: 5,
  //   emissions: 'none',
  //   diaper: 'none',
  //   supplements: 'none',
  //   mealTime: 10,
  // }, {
  //   id: 9,
  //   date: '2024-09-02',
  //   time: '19:00',
  //   amount: 20,
  //   willingness: 5,
  //   emissions: 'none',
  //   diaper: 'none',
  //   supplements: 'none',
  //   mealTime: 10,
  // }])
const table = ref({
  aggregated_table_headers: [
    {label: 'Date', key: 'date'},
    {label: 'Total intake', key: 'totalAmount'},
    {label: 'Average meal intake', key: 'averageAmount'},
    {label: 'Number of meals', key: 'numberOfMeals'},
  ],
      raw_table_headers: [
    {label: 'Time', key: 'time'},
    {label: 'Amount', key: 'amount'},
    {label: 'Meal Time', key: 'mealTime'},
    {label: 'Willingness', key: 'willingness'},
    {label: 'Emissions', key: 'emissions'},
    {label: 'Diaper', key: 'diaper'},
    {label: 'Supplements', key: 'supplements'},
  ],
})
const dialog = ref({
  show: false,
      fullscreen: false,
      width: 600,
      persistent: false,
      persistentNoAnimation: false,
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      overlayOpacity: 0.5
})




async function deleteItem(item) {
  await counterStore.deleteRecord(item.id)
}
async function onSubmit(data) {
  await counterStore.addRecord(data)
  dialog.value.show = false
}
function dailyRecords(records, item) {
  return records.filter(r => r.date === item.date).map(r => {
    return {
      ...r,
      control: 'control'
    }
  }).sort((a, b) => {
    return new Date(`${b.date} ${b.time}`).getTime() - new Date(`${a.date} ${a.time}`).getTime()
  });
}
function dailyAggregates(records, descending = true) {
  return records.reduce((acc, curr) => {
    const existingReport = acc.find(r => r.date === curr.date);
    if (existingReport) {
      existingReport.totalAmount += curr.amount || 0;
      existingReport.numberOfMeals += curr.amount ? 1 : 0;
      existingReport.averageAmount = Math.round(curr.amount ? existingReport.totalAmount / existingReport.numberOfMeals : existingReport.averageAmount);
      existingReport.totalMealTime += curr.amount ? curr.mealTime : 0;
      existingReport.averageMealTime = curr.amount ? existingReport.totalMealTime / existingReport.numberOfMeals : existingReport.averageMealTime;
    } else {
      acc.push({
        date: curr.date,
        totalAmount: curr.amount || 0,
        averageAmount: curr.amount || 0,
        numberOfMeals: curr.amount ? 1 : 0,
        averageMealTime: curr.amount ? curr.mealTime : 0,
        totalMealTime: curr.amount ? curr.mealTime : 0,
      });
    }
    return acc;
  }, []).sort((a, b) => a.date > b.date ? descending ? -1 : 1 : descending ? 1 : -1);
}

const chartData = computed(()=> {
  return dailyAggregates(rawRecords.value).sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime()
  })
})


</script>