export function getCurrentTime() {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
}


export function convertTimeStringToDate(timeString) {
    // Ensure the timeString is in HH:MM format
    const [hours, minutes] = timeString.split(':').map(Number);

    // Create a new Date object with today's date and the given time
    const now = new Date();
    now.setHours(hours, minutes, 0, 0); // Set hours, minutes, seconds, and milliseconds

    return now;
}

export const convertStringDateAndStringTimeToDate = (dateString, timeString) => {
    if(!dateString || !timeString){
        return new Date();
    }
    const [year, month, day] = dateString.split('-').map(Number);
    const [hours, minutes] = timeString.split(':').map(Number);

    const date = new Date(year, month - 1, day, hours, minutes, 0, 0);

    return date;
}

export function kebabCaseToCamelCase(str) {
    return str.replace(/-([a-z])/g, (g) => ' ' + g[1].toUpperCase());
}


export function kebabCaseToTitleCase(str) {
    return str.replace(/-([a-z])/g, (g) => ' ' + g[1].toUpperCase()).replace(/\b\w/g, (l) => l.toUpperCase());
}

