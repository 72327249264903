import {defineStore} from "pinia";
import {computed, ref} from "vue";
import {addNursingRecord, deleteNursingRecord, fetchNursingRecords} from "@/services/nursing-records.service";

export const useCounterStore = defineStore('counter', () => {

    const data = ref([])

    async function fetchRecords(){
        try {
            const response = await fetchNursingRecords();
            data.value = response.data;
            return response.data;
        } catch (error) {
            console.log(error)
        }
        return null
    }

    async function addRecord(record){
        try {
            const response = await addNursingRecord(record)
            data.value.push(response.data);
            return response.data;
        } catch (error) {
            console.log("addRecord", error);
        }
        return null
    }

    async function deleteRecord(id){
        try {
            await deleteNursingRecord(id);
            data.value = data.value.filter(r => r.id !== id);
            return id;
        } catch (error) {
            console.log("deleteRecord", error);
        }
        return null
    }

    const rawRecords = computed( ()=> data.value)
    const rawRecordsByDate = computed((date) => data.value.filter(r => r.date === date))
    const groupedRecordsByDate = computed(() => {
        return data.value.reduce((acc, record) => {
            if (!acc[record.date]) {
                acc[record.date] = []
            }
            acc[record.date].push(record)
            return acc
        }, {})
    })

    return { data, fetchRecords, addRecord, deleteRecord, rawRecords, rawRecordsByDate, groupedRecordsByDate }
})