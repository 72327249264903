import {GET} from "@/services/base.http";

const auth_url = "/auth";

export function userService(){
    const get = async (id)=>{
        const response = await GET(`${auth_url}/user/${id}`)
        return response.json()
    }
    return {
        get,
    }
}