<template>
  <w-form>
    <w-flex wrap class="text-center">
      <div class="xs6 pa1">
        <w-input type="date" label-position="left" label="Date" outline v-model="data.date"  :validators="[validators.required]"/>
      </div>
      <div class="xs6 pa1">
        <w-input type="time" label-position="left" label="Time" outline v-model="data.time"  :validators="[validators.required]"/>
      </div>

      <w-divider class="my6 mx-3"></w-divider>

      <div class="xs12 pa1 text-left">
        <w-input type="number" label-position="left" label="Food intake (ml)"  v-model="data.amount"/>
      </div>
      <div class="xs12 pa1 text-left">
        <w-select :items="selectValues.emissions" v-model="data.emissions" label="Food emissions" label-position="left" :disabled="!foodGiven">
          <template #item="{ item }">
            <w-icon color="primary" class="mr1">mdi mdi-{{ item.icon }}</w-icon>
            <span class="ml1">{{ item.label }}</span>
          </template>
        </w-select>
      </div>
      <div class="xs12 pa1 text-left">
        <w-select :items="selectValues.diaper" v-model="data.diaper" label="Diaper change" label-position="left">
          <template #item="{ item }">
            <w-icon color="primary" class="mr1">mdi mdi-{{ item.icon }}</w-icon>
            <span class="ml1">{{ item.label }}</span>
          </template>
        </w-select>
      </div>
      <div class="xs12 pa1 text-left">
        <w-select :items="selectValues.supplements" v-model="data.supplements" label="Supplements" label-position="left">
          <template #item="{ item }">
            <w-icon color="primary" class="mr1">mdi mdi-{{ item.icon }}</w-icon>
            <span class="ml1">{{ item.label }}</span>
          </template>
        </w-select>
      </div>
      <div class="xs12 pa1 text-left">
        <div class="mb4 primary">Willingness to Eat</div>
        <w-slider
            v-model="data.willingness"
            step-labels
            :step="1"
            :min="1"
            :max="5"
            :disabled="!foodGiven">
        </w-slider>
      </div>
      <w-divider class="my6 mx-3"></w-divider>

      <div class="xs12 pa0 text-right">
        <w-button class="ma1" bg-color="secondary" icon="wi-cross" @click="onCancel"></w-button>
        <w-button class="ma1" bg-color="primary" icon="wi-check" :disabled="valid === false" @click="onSubmit"></w-button>
      </div>

    </w-flex>
  </w-form>

</template>

<script>
import { WFlex } from 'wave-ui'
import '@mdi/font/css/materialdesignicons.min.css'
import {getCurrentTime} from "@/shared/utils";

export default {
  emits: ['submit', 'cancel'],
  components: { WFlex },
  computed:{
    foodGiven(){
      return this.data.amount > 0;
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
      this.data = {
        date: new Date().toISOString().split('T')[0],
        time: getCurrentTime(),
        amount: 0,
        willingness: 5,
        emissions: 'none',
        diaper: 'none',
        supplements: 'none'
      }
    },
    onSubmit() {
      let data = {
        date: this.data.date,
        time: this.data.time,
        emissions: this.data.emissions,
        diaper: this.data.diaper,
        supplements: this.data.supplements
      }
      if(this.data.amount > 0){
        data.amount = this.data.amount;
        data.willingness = this.data.willingness;
      }
      this.$emit('submit', data);
      this.data = {
        date: new Date().toISOString().split('T')[0],
        time: getCurrentTime(),
        amount: 0,
        willingness: 5,
        emissions: 'none',
        diaper: 'none',
        supplements: 'none'
      }
    }
  },
  data: () => ({
    valid: null,
    data:{
      date: new Date().toISOString().split('T')[0],
      time: getCurrentTime(),
      amount: 0,
      willingness: 5,
      emissions: 'none',
      diaper: 'none',
      supplements: 'none'
    },
    selectValues:{
      diaper:[
        {
          label:'Change skipped',
          icon: 'baby-face-outline',
          value: 'none'
        },
        {
          label:'Diaper was dry',
          icon: 'alert-circle-outline',
          value: 'dry'
        },
        {
          label:'Diaper was wet',
          icon: 'water',
          value: 'wet'
        },
        {
          label:'Diaper had poop',
          icon: 'emoticon-poop',
          value: 'poop'
        }
      ],
      emissions: [{
        label: 'No emissions',
        icon: 'water-off',
        value: 'none'
      },{
        label: 'Slip',
        icon: 'water-outline',
        value: 'slip'
      },
        {
          label: 'Vomit',
          icon: 'water',
          value: 'vomit'
        }],
      supplements:[{
        label: 'Not given',
        icon: 'water-off',
        value: 'none'
      },{
        label: 'Vitamin D',
        icon: 'pill',
        value: 'vitamin-d'
      },
        {
          label: 'Iron',
          icon: 'soldering-iron',
          value: 'iron'
        },{
          label: 'Medicine',
          icon: 'medical-bag',
          value: 'medicine'
        }]
    },

    validators: {
      required: value => !!value || 'This field is required'
    },
  })
};
</script>