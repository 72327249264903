<template>
  <div v-if="chartData && chartData.labels && chartData.labels.length > 0">
    <line-chart :data="chartData" :options="chartOptions" />
  </div>
  <div v-else>
    <p>No {{ label }} data available for the chart.</p>
  </div>
</template>

<script setup>

import {computed, defineProps, defineModel } from "vue";
import { Line as LineChart } from "vue-chartjs";
const dob = new Date('2024-02-28')
const months = Array.from({ length: 24 }, (_, i) => {
  const date = new Date(dob);
  date.setMonth(date.getMonth() + i + 1);
  return date.toISOString().slice(0, 7);
});
const values = [2.4,3.4,4.4,5.05,5.6,6,6.4,6.8,7,7.1,7.2,7.3,7.4,7.5,7.6,7.7,7.8,7.9,8,8.1,8.2,8.3,8.4,8.5]

const modelValue = defineModel({
  prop: 'modelValue',
  event: 'update:modelValue'
})

const chartData = computed(() => {
  return {
    labels: modelValue.value.map(props.byLabel),
    datasets: [
      {
        label: props.label,
        data: modelValue.value.map(props.byValue),
        fill: false,
        borderColor: 'blue',
        tension: 0.5
      }
      ,{
        label: props.label,
        data: values,
        fill: false,
        borderColor: 'red',
        tension: 0.5
      }
    ]
  }
})

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  byLabel: {
    type: Function,
    required: true
  },
  byValue: {
    type: Function,
    required: true
  }
})


const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'category',
      labels: months
    }
  }
}
</script>