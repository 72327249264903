<template>
  <w-card class="mt10">
    <template #title>
      <w-toolbar :class="`${constants.colors.diaper}--bg`">
        <w-icon class="mr2" md>{{constants.icons.diaper}}</w-icon>
        <span>Diapers Details</span>
        <div class="spacer"></div>
        <w-icon xl class="ml2 md btn" @click="showDialog=true">mdi mdi-pencil-plus-outline</w-icon>
        <w-icon class="ml2 md btn" @click="emit('navigateTo','/')">{{constants.icons.back}}</w-icon>
      </w-toolbar>
    </template>
    <ExpendingTableComponent
        :value="data"
        :aggregate-func="aggregateRecords"
        :aggregation-headers="aggregated_table_headers"
        :headers="raw_table_headers"
        :filter-by="filterRecordsByDate"
        :showAddButton="false"
        @delete="onDelete">
    </ExpendingTableComponent>

    <form-dialog-component
        title="New Diaper"
        :icon = "constants.icons.diaper"
        :color = "constants.colors.diaper"
        :show="showDialog">
      <DiaperForm @submit="onFormSubmit" @cancel="showDialog=false"></DiaperForm>
    </form-dialog-component>
  </w-card>
</template>
<script setup>


import { constants } from "@/shared/constants";
import {  useDiaperStore } from "@/stores/diaper.store";
import ExpendingTableComponent from "@/components/ExpendingTableComponent.vue";
import {storeToRefs} from "pinia";
import FormDialogComponent from "@/components/FormDialogComponent.vue";
import {ref, defineEmits} from "vue";
import DiaperForm from "@/components/DiaperForm.vue";

const emit = defineEmits(['navigateTo'])
const showDialog = ref(false)
const diaperStore = useDiaperStore()

const { data } = storeToRefs(diaperStore)

const aggregated_table_headers = [
  {label: 'Date', key: 'date'},
  {label: 'Wet Diapers', key: 'totalWet'},
  {label: 'Dirty Diapers', key: 'totalDirty'},
  {label: 'Dry Diapers', key: 'totalDry'},
]
const raw_table_headers =[
  {label: 'Time', key: 'time'},
  {label: 'Diaper Content', key: 'content'},
]

function aggregateRecords(data){
  return data.reduce((acc, current) => {

    const curDate = acc.find(r => r.date === current.date)
    if(curDate) {
      switch (curDate.content){
        case 'wet':
          curDate.totalWet += 1;
          break;
        case 'poop':
          curDate.totalDirty += 1;
          break;
        case 'dry':
          curDate.totalDry += 1;
          break;
      }
    } else {
      acc.push({
        date: current.date,
        totalWet: current.content === 'wet' ? 1 : 0,
        totalDirty: current.content === 'poop' ? 1 : 0,
        totalDry: current.content === 'dry' ? 1 : 0,
      });
    }
    return acc;
  },[])
}
function filterRecordsByDate(records, item) {
  if(records){
    return records.filter(r => r.date === item.date).map(r => {
      return {
        ...r,
        control: 'control'
      }
    }).sort((a, b) => {
      return new Date(`${b.date} ${b.time}`).getTime() - new Date(`${a.date} ${a.time}`).getTime()
    });
  } else {
    return []
  }
}

function onFormSubmit(value) {
  diaperStore.addRecord(value)
  showDialog.value = false
  console.log('Diaper Form Submitted:', value)
}

function onDelete(item) {
  diaperStore.deleteRecord(item)
  console.log('Diaper Record Deleted:', item)
}

</script>