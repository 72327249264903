<template>
  <w-card class="mt10">
    <template #title>
      <w-toolbar :class="`${constants.colors.medicine}--bg`">
        <w-icon class="mr2" md>{{constants.icons.medicine}}</w-icon>
        <span>Medicines Details</span>
        <div class="spacer"></div>
        <w-icon xl class="ml2 md btn" @click="showDialog=true">mdi mdi-pencil-plus-outline</w-icon>
        <w-icon class="ml2 md btn" @click="emit('navigateTo','/')">{{constants.icons.back}}</w-icon>
      </w-toolbar>
    </template>
    <ExpendingTableComponent
        :value="data"
        :aggregate-func="aggregateRecords"
        :aggregation-headers="aggregated_table_headers"
        :headers="raw_table_headers"
        :filter-by="filterRecordsByDate"
        :showAddButton="false"
        @delete="onDelete">
    </ExpendingTableComponent>

    <form-dialog-component
        title="New Medicine"
        :icon = "constants.icons.medicine"
        :color = "constants.colors.medicine"
        :show="showDialog">
      <MeasurementForm @submit="onFormSubmit" @cancel="showDialog=false"></MeasurementForm>
    </form-dialog-component>
  </w-card>
</template>
<script setup>


import { constants } from "@/shared/constants";
import {storeToRefs} from "pinia";
import FormDialogComponent from "@/components/FormDialogComponent.vue";
import ExpendingTableComponent from "@/components/ExpendingTableComponent.vue";
import {ref, defineEmits, computed} from "vue";
import {  useWeightStore } from "@/stores/weight.store";
import {  useHeightStore } from "@/stores/height.store";
import {  useHeadSizeStore } from "@/stores/head-size.store";
import MeasurementForm from "@/components/MeasurementForm.vue";

const emit = defineEmits(['navigateTo'])
const showDialog = ref(false)
const weightStore = useWeightStore()
const heightStore = useHeightStore()
const headCircumferenceStore = useHeadSizeStore()

const { data:weightData } = storeToRefs(weightStore)
const { data:heightData } = storeToRefs(heightStore)
const { data:headCircumferenceData } = storeToRefs(headCircumferenceStore)

const data = computed(() => {
  console.log('Weight Data:',  [...weightData.value, ...heightData.value, ...headCircumferenceData.value])
  const weights = weightData.value.reduce((acc, current) => {
    const existing = acc.find(r => r.date === current.date && r.time === current.time)
    if(existing) {
      existing.weightId = current.id
      existing.weight = current.weight
    } else {
      acc.push({
        weightId: current.id,
        date: current.date,
        time: current.time,
        weight: current.weight,
      })
    }
    return acc;
  },[])

  const weightAndHeight = heightData.value.reduce((acc, current) => {
    const existing = acc.find(r => r.date === current.date && r.time === current.time)
    if(existing) {
      existing.heightId = current.id
      existing.height = current.height
    } else {
      acc.push({
        heightId: current.id,
        date: current.date,
        time: current.time,
        height: current.height,
      })
    }
    return acc;
  },weights)

  return headCircumferenceData.value.reduce((acc, current) => {
    const existing = acc.find(r => r.date === current.date && r.time === current.time)
    if(existing) {
      existing.headCircumferenceId = current.id
      existing.headCircumference = current.headCircumference
    } else {
      acc.push({
        headCircumferenceId: current.id,
        date: current.date,
        time: current.time,
        headCircumference: current.headCircumference
      })
    }
    return acc;
  },weightAndHeight)
})


const aggregated_table_headers = [
  {label: 'Date', key: 'date'},
  {label: 'Weight', key: 'weight'},
  {label: 'Height', key: 'height'},
  {label: 'Head Circumference', key: 'headCircumference'},
]
const raw_table_headers =[
  {label: 'Time', key: 'time'},
  {label: 'Weight', key: 'weight'},
  {label: 'Height', key: 'height'},
  {label: 'Head Circumference', key: 'headCircumference'},
]



function aggregateRecords(data){
  return data.reduce((acc, current) => {
    const curDate = acc.find(r => r.date === current.date)
    if(curDate) {
        curDate.weight = current.weight > curDate.weight ? current.weight : curDate.weight
        curDate.height = current.height > curDate.height ? current.height : curDate.height
        curDate.headCircumference = current.headCircumference > curDate.headCircumference ? current.headCircumference : curDate.headCircumference
    } else {
      acc.push({
        date: current.date,
        weight: current.weight,
        height: current.height,
        headCircumference: current.headCircumference
      });
    }
    return acc;
  },[])
}
function filterRecordsByDate(records, item) {
  if(records){
    return records.filter(r => r.date === item.date).map(r => {
      return {
        ...r,
        control: 'control'
      }
    }).sort((a, b) => {
      return new Date(`${b.date} ${b.time}`).getTime() - new Date(`${a.date} ${a.time}`).getTime()
    });
  } else {
    return []
  }
}

function onFormSubmit(value) {
  if(value.weight) {
    weightStore.addRecord({
      date: value.date,
      time: value.time,
      weight: value.weight
    })
  }
  if(value.height) {
    heightStore.addRecord({
      date: value.date,
      time: value.time,
      height: value.height
    })
  }
  if(value.headCircumference) {
    headCircumferenceStore.addRecord({
      date: value.date,
      time: value.time,
      headCircumference: value.headCircumference
    })
  }
  showDialog.value = false
  console.log('Measurements Form Submitted:', value)
}


function onDelete(item) {
  console.log('Deleting Medicine Record:', item)
  if(item.weightId) {
    weightStore.deleteRecord({
      id:item.weightId
    })
  }
  if(item.heightId) {
    heightStore.deleteRecord({
      id: item.heightId
    })
  }
  if(item.headCircumferenceId) {
    headCircumferenceStore.deleteRecord({
      id: item.headCircumferenceId
    })
  }
  console.log('Medicine Record Deleted:', item)
}

</script>