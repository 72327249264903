export const constants = {
    colors: {
        diaper: 'pink-light5',
        food: 'deep-purple-light5',
        medicine: 'blue-light5',
        measurements: 'green-light5',
    },
    icons: {
        diaper: 'mdi mdi-human-baby-changing-table',
        food: 'mdi mdi-silverware-variant',
        medicine: 'mdi mdi-pill-multiple',
        medical_bag: 'mdi mdi-medical-bag',
        measurements: 'mdi mdi-gauge',//'mdi mdi-tape-measure',
        back: 'mdi mdi-arrow-left',
    }
}