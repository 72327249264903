import {defineStore} from "pinia";
import {computed, ref} from "vue";
import {getRepo} from "@/services/remote.repository";
import {useUserStore} from "@/stores/user.store";

const repository =  getRepo('diaper-change')

export const useDiaperStore = defineStore('diaper', () => {
    const userStore = useUserStore()
    const data = ref([])

    async function fetchRecords() {
        try {
            const accountId = userStore?.user?.accountId;
            const response = await repository.fetchRecords(accountId)
            data.value = response.data;
            return response.data;
        } catch (error) {
            console.log("fetchRecords",error)
        }
        return null
    }

    async function addRecord(record){
        try {
            const accountId = userStore?.user?.accountId;
            const response = await repository.addRecord(accountId,record)
            data.value.push(response.data);
            return response.data;
        } catch (error) {
            console.log("addRecord", error);
        }
        return null
    }

    async function deleteRecord(record){
        try {
            const accountId = userStore?.user?.accountId;
            const response = await repository.deleteRecord(accountId,record)
            data.value = data.value.filter(r => r.id !== record.id);
            return response.data;
        } catch (error) {
            console.log("deleteRecord", error);
        }
        return null
    }

    const lastRecord = computed(() => {
        return data.value.reduce((prev, current) => {
            return new Date(`${prev.date}T${prev.time}:00.000Z`).getTime() > new Date(`${current.date}T${current.time}:00.000Z`).getTime() ? prev : {...current}
        }, {data: '1970-01-01', time: '00:00'})
    })


    return {
        data,
        fetchRecords,
        addRecord,
        deleteRecord,
        lastRecord
    }
})