import { createRouter, createWebHistory } from 'vue-router';
import ReportsPage from "@/components/ReportsPage.vue";
import GeneralForm from "@/components/GeneralForm.vue";
import AIRecommendationsPage from "@/components/AIRecomendationsPage.vue";
import NursingRecordsPage from "@/page/NursingRecordsPage.vue";
import HomePage from "@/page/HomePage.vue";
import FoodPage from "@/page/FoodPage.vue";
import DiaperPage from "@/page/DiaperPage.vue";
import MedicinePage from "@/page/MedicinePage.vue";
import MeasurementsPage from "@/page/MeasurementsPage.vue";
import ProfilePage from "@/page/ProfilePage.vue";
import WeightGraphPage from "@/page/WeightGraphPage.vue";
import LoginPage from "@/page/LoginPage.vue";

import { useUserStore } from "@/stores/user.store";
import SignupPage from "@/page/SignupPage.vue";


const routes = [
    { path: '/', component: HomePage },
    { path: '/login', component: LoginPage },
    { path: '/signup', component: SignupPage },
    { path: '/food', component: FoodPage , meta: { requiresAuth: true }},
    { path: '/diaper', component: DiaperPage, meta: { requiresAuth: true } },
    { path: '/medication', component: MedicinePage , meta: { requiresAuth: true } },
    { path: '/measurements', component: MeasurementsPage, meta: { requiresAuth: true } },
    { path: '/profile', component: ProfilePage , meta: { requiresAuth: true } },
    { path: '/account', component: GeneralForm , meta: { requiresAuth: true } },
    { path: '/weight-graph', component: WeightGraphPage , meta: { requiresAuth: true } },
    { path: '/reports', component: ReportsPage , meta: { requiresAuth: true } },
    { path: '/recommendations', component: AIRecommendationsPage, meta: { requiresAuth: true } },
    { path: '/nursing', component: NursingRecordsPage , meta: { requiresAuth: true } },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach(async (to, from, next) => {
    console.log('beforeEach', to.path);
    const userStore = useUserStore();
    if(to.path === '/logout') {
        await userStore.logout();
        next('/login');
    } else if((to.path === '/login' || to.path === '/signup' )){
        if(userStore.isLoggedIn){
            next('/');
        } else {
            next();
        }
    } else if(!userStore.isLoggedIn) {
        await userStore.load()
        if(!userStore.isLoggedIn){
            next('/login');
        } else {
            next();
        }
    }  else{
        next();
    }
});

export default router;
