<template>

    <div>
      <h3 class="mt-4">Last 7 days food intake</h3>
      <div>
        <b-table :items="report" :fields="fields" responsive="sm">
          <template #cell(show_details)="row">
            <b-button size="sm" @click="row.toggleDetails" class="mr-2">
              {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
            </b-button>

            <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
            <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails" style="display: none">
            </b-form-checkbox>
          </template>

          <template #row-details="row">
            <b-row>
              <b-col><b>Time</b></b-col>
              <b-col><b>ml</b></b-col>
              <b-col><b>Spit Out</b></b-col>
              <b-col><b>Willingness to Eat</b></b-col>
              <b-col><b></b></b-col>
            </b-row>
            <b-row v-for="item in this.extendedItems(row.item.date)" :key="item.id">
              <b-col>{{ item.time }}</b-col>
              <b-col>{{ item.amount }}</b-col>
              <b-col>{{ item.spitOut }}</b-col>
              <b-col>{{ item.willingness }}</b-col>
              <b-col class="delete" @click="this.delete(item.id)"></b-col>
            </b-row>
          </template>
        </b-table>
      </div>


      <h3 class="mt-4">Weight Over Time</h3>
      <div v-if="chartData && chartData.labels && chartData.labels.length > 0">
        <line-chart :data="chartData" :options="chartOptions" />
      </div>
      <div v-else>
        <p>No weight data available for the chart.</p>
      </div>
    </div>

  <div class="calendar-page">
    <h1>Event Calendar</h1>
    <vue-cal
        :events="events"
        class="vuecal--green-theme"
        default-view="week"
        :min-date="minDate"
        :max-date="maxDate"
        today-button
    >
    </vue-cal>
  </div>
</template>

<script>
import {convertTimeStringToDate} from "@/shared/utils";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
} from 'chart.js';
import { BTable } from "bootstrap-vue-3";
import {Line} from "vue-chartjs";
import {defineComponent} from "vue";
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale);
export default defineComponent({
  components: {
    BTable,
    LineChart: Line,
    VueCal
  },
  computed: {
    minDate () {
      return new Date().subtractDays(10)
    },
    maxDate () {
      return new Date().addDays(10)
    }
  },
  methods: {
    onEventClick(event) {
      alert(`Event: ${event.title}\nFrom: ${event.start}\nTo: ${event.end}`);
    },
    delete(id) {
      this.detailedReport = this.detailedReport.filter(item => item.id !== id);
      this.report = this.detailedReport.reduce((acc, curr) => {
        const existingReport = acc.find(r => r.date === curr.date);
        if (existingReport) {
          existingReport.totalAmount += curr.amount;
          existingReport.numberOfMeals += 1;
          existingReport.averageAmount = existingReport.totalAmount / existingReport.numberOfMeals;
        } else {
          acc.push({
            date: curr.date,
            totalAmount: curr.amount,
            averageAmount: curr.amount,
            numberOfMeals: 1
          });
        }
        return acc;
      }, []).sort((a, b) => a.date > b.date ? -1 : 1);
    },
    extendedItems(date) {
      return this.detailedReport.filter(item => item.date === date).sort((a, b) =>{
        return convertTimeStringToDate(a.time) > convertTimeStringToDate(b.time) ? 1 : -1;
      });
    }
  },
  created() {
    this.detailedReport = [{
      id: 1,
      date: '2024-08-01',
      time: '23:00',
      amount: 50,
      willingness: 5,
      spitOut: false
    }, {
      id: 2,
      date: '2024-08-01',
      time: '13:00',
      amount: 50,
      willingness: 5,
      spitOut: false
    }, {
      id: 3,
      date: '2024-08-02',
      time: '12:00',
      amount: 50,
      willingness: 5,
      spitOut: false
    }, {
      id: 4,
      date: '2024-08-02',
      time: '13:00',
      amount: 50,
      willingness: 5,
      spitOut: false
    }, {
      id: 5,
      date: '2024-08-03',
      time: '12:00',
      amount: 50,
      willingness: 5,
      spitOut: false
    }, {
      id: 6,
      date: '2024-08-03',
      time: '14:00',
      amount: 50,
      willingness: 5,
      spitOut: false
    }, {
      id: 7,
      date: '2024-08-03',
      time: '15:00',
      amount: 10,
      willingness: 5,
      spitOut: false
    }];


    this.report = this.detailedReport.reduce((acc, curr) => {
      const existingReport = acc.find(r => r.date === curr.date);
      if (existingReport) {
        existingReport.totalAmount += curr.amount;
        existingReport.numberOfMeals += 1;
        existingReport.averageAmount = existingReport.totalAmount / existingReport.numberOfMeals;
      } else {
        acc.push({
          date: curr.date,
          totalAmount: curr.amount,
          averageAmount: curr.amount,
          numberOfMeals: 1
        });
      }
      return acc;
    }, []).sort((a, b) => a.date > b.date ? -1 : 1);
  },
  data() {
    return {
      events: [
        {
          start: '2024-09-07 10:15',
          end: '2024-09-07 10:45',
          title: 'Doctor Appointment',
        },
        {
          start: '2024-09-10 14:00',
          end: '2024-09-10 15:00',
          title: 'Baby Checkup',
        },
        {
          start: '2024-09-12 09:00',
          end: '2024-09-12 9:10',
          content: '<i class="icon material-icons">shopping_cart</i>',
          class: 'intake'
        },        {
          start: '2024-09-12 09:00',
          end: '2024-09-12 9:10',
          class: 'poop'
        },  {
          start: '2024-09-12 09:00',
          end: '2024-09-12 9:10',
          class: 'pee'
        }, {
          start: '2024-09-12 09:00',
          end: '2024-09-12 9:10',
          class: 'throwup'
        },
      ],
      report: [],
      detailedReport: [],
      fields: [{
        key: 'date',
        label: 'Date'
      }, {
        key: 'totalAmount',
        label: 'ml'
      }, {
        key: 'averageAmount',
        label: 'Average Meal (ml)'
      }, 'numberOfMeals','show_details'],
      hourlyFields: ['time', 'amount'],
      chartData: {
        labels: ['2024-08-01', '2024-08-02', '2024-08-03'],
        datasets: [
          {
            label: 'Weight',
            data: [3.3, 3.4, 3.5],
            fill: false,
            borderColor: 'blue'
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  }
})
</script>

<style scoped>
.delete:before{
  content: 'X';

}
.delete {
  color: red;
  font-weight: bolder;
  cursor: pointer;
}

h2 {
  margin-top: 20px;
}

.vuecal {
  width: 100%;
  height: 600px;
}

.calendar-page {
  padding: 20px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}



</style>
<style>
.vuecal__event.intake {background-color: rgba(253, 222, 66, 0.9);border: 1px solid rgba(253, 222, 66, 0.9);color: #fff;}
.vuecal__event.poop {background-color: rgba(85, 48, 13, 0.9);border: 1px solid rgba(85, 48, 13, 0.9);color: #fff;}
.vuecal__event.pee {background-color: rgba(253, 156, 66, 0.9);border: 1px solid rgb(233, 136, 46);color: #fff;}
.vuecal__event.throwup {background-color: rgba(37, 119, 19, 0.9);border: 1px solid rgba(37, 119, 19, 0.9);color: #fff;}

</style>



<!--<template>-->
<!--  <svg-icon type="mdi" :path="path"></svg-icon>-->
<!--</template>-->

<!--<script>-->
<!--import SvgIcon from '@jamescoyle/vue-icon';-->
<!--import { mdiBabyBottle } from '@mdi/js';-->

<!--export default {-->
<!--  name: "my-component",-->
<!--  components: {-->
<!--    SvgIcon-->
<!--  },-->
<!--  data() {-->
<!--    return {-->
<!--      path: mdiBabyBottle,-->
<!--      path: mdiBabyBottleOutline,-->
<!--      path: mdiHumanBabyChangingTable,-->
<!--      path: mdiEmoticonPoop,-->
<!--      path: mdiWater,-->

<!--    }-->
<!--  }-->
<!--}-->
<!--</script>-->



<!--<template>-->
<!--  <div>-->
<!--    <h3 class="mt-4">Last 7 days food intake</h3>-->
<!--    <b-table :items="displayReports" :fields="fields" striped responsive="sm">-->
<!--      <template #cell(show_details)="row">-->
<!--        <b-button size="sm" @click="row.toggleDetails" class="mr-2">-->
<!--          {{ row.detailsShowing ? 'Hide' : 'Show'}} Details-->
<!--        </b-button>-->

<!--        &lt;!&ndash; As `row.showDetails` is one-way, we call the toggleDetails function on @change &ndash;&gt;-->
<!--        <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails">-->
<!--          Details via check-->
<!--        </b-form-checkbox>-->
<!--      </template>-->

<!--      <template #row-details="row">-->
<!--        <b-card>-->
<!--          <b-row class="mb-2">-->
<!--            <b-col sm="3" class="text-sm-right"><b>Age:</b></b-col>-->
<!--            <b-col>5</b-col>-->
<!--          </b-row>-->

<!--          <b-row class="mb-2">-->
<!--            <b-col sm="3" class="text-sm-right"><b>Is Active:</b></b-col>-->
<!--            <b-col>false</b-col>-->
<!--          </b-row>-->

<!--          <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>-->
<!--        </b-card>-->
<!--      </template>-->
<!--    </b-table>-->
<!--    <h3 class="mt-4">Weight Over Time</h3>-->
<!--    <div v-if="chartData && chartData.labels && chartData.labels.length > 0">-->
<!--      <line-chart :data="chartData" :options="chartOptions"/>-->
<!--    </div>-->
<!--    <div v-else>-->
<!--      <p>No weight data available for the chart.</p>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--import { defineComponent } from 'vue';-->
<!--import { Line } from 'vue-chartjs';-->
<!--import {-->
<!--  Chart as ChartJS,-->
<!--  Title,-->
<!--  Tooltip,-->
<!--  Legend,-->
<!--  LineElement,-->
<!--  CategoryScale,-->
<!--  LinearScale,-->
<!--  PointElement-->
<!--} from 'chart.js';-->
<!--import { BTable } from "bootstrap-vue-3";-->

<!--ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale);-->

<!--export default defineComponent({-->
<!--  components: {-->
<!--    BTable,-->
<!--    LineChart: Line,-->
<!--  },-->
<!--  created() {-->
<!--    const mockReportData = [{-->
<!--      date: '2024-08-01',-->
<!--      time: '12:00',-->
<!--      amount: 50,-->
<!--    }, {-->
<!--      date: '2024-08-01',-->
<!--      time: '13:00',-->
<!--      amount: 50,-->
<!--    }, {-->
<!--      date: '2024-08-02',-->
<!--      time: '12:00',-->
<!--      amount: 50,-->
<!--    }, {-->
<!--      date: '2024-08-02',-->
<!--      time: '13:00',-->
<!--      amount: 50,-->
<!--    }, {-->
<!--      date: '2024-08-03',-->
<!--      time: '12:00',-->
<!--      amount: 50,-->
<!--    }, {-->
<!--      date: '2024-08-03',-->
<!--      time: '14:00',-->
<!--      amount: 50,-->
<!--    }, {-->
<!--      date: '2024-08-03',-->
<!--      time: '15:00',-->
<!--      amount: 10,-->
<!--    }];-->

<!--    this.hourlyData = mockReportData;-->
<!--    this.reports = mockReportData.reduce((acc, curr) => {-->
<!--      const existingReport = acc.find(r => r.date === curr.date);-->
<!--      if (existingReport) {-->
<!--        existingReport.totalAmount += curr.amount;-->
<!--        existingReport.numberOfMeals += 1;-->
<!--        existingReport.averageAmount = existingReport.totalAmount / existingReport.numberOfMeals;-->
<!--      } else {-->
<!--        acc.push({-->
<!--          date: curr.date,-->
<!--          totalAmount: curr.amount,-->
<!--          averageAmount: curr.amount,-->
<!--          numberOfMeals: 1-->
<!--        });-->
<!--      }-->
<!--      return acc;-->
<!--    }, []);-->

<!--    this.hourlyData = mockReportData;-->
<!--  },-->
<!--  data() {-->
<!--    return {-->
<!--      reports: [],-->
<!--      hourlyData: [],-->
<!--      expandedDate: null,-->
<!--      fields: [-->
<!--        { key: 'date', label: 'Date' },-->
<!--        { key: 'totalAmount', label: 'Total Amount' },-->
<!--        { key: 'averageAmount', label: 'Average Amount' },-->
<!--        { key: 'numberOfMeals', label: 'Number of Meals' },-->
<!--        { key: 'show_details' }-->
<!--      ],-->
<!--      hourlyFields: [-->
<!--        { key: 'time', label: 'Time' },-->
<!--        { key: 'amount', label: 'Amount' }-->
<!--      ],-->
<!--      chartData: {-->
<!--        labels: ['2024-08-01', '2024-08-02', '2024-08-03'],-->
<!--        datasets: [-->
<!--          {-->
<!--            label: 'Weight',-->
<!--            data: [3.3, 3.4, 3.5],-->
<!--            fill: false,-->
<!--            borderColor: 'blue'-->
<!--          }-->
<!--        ]-->
<!--      },-->
<!--      chartOptions: {-->
<!--        responsive: true,-->
<!--        maintainAspectRatio: false-->
<!--      }-->
<!--    };-->
<!--  },-->
<!--  computed: {-->
<!--    displayReports() {-->
<!--      return this.reports.map(report => ({-->
<!--        ...report,-->
<!--        details: this.expandedDate === report.date ? 'show' : 'hide'-->
<!--      }));-->
<!--    }-->
<!--  },-->
<!--  methods: {-->
<!--    toggleExpand(date) {-->
<!--      this.expandedDate = this.expandedDate === date ? null : date;-->
<!--    },-->
<!--    getHourlyData(date) {-->
<!--      return this.hourlyData.filter(data => data.date === date);-->
<!--    }-->
<!--  }-->
<!--});-->
<!--</script>-->

<!--<style scoped>-->
<!--h2 {-->
<!--  margin-top: 20px;-->
<!--}-->
<!--</style>-->



<!--<template>-->
<!--  <div>-->
<!--    <h3 class="mt-4">Last 7 days food intake</h3>-->
<!--    <b-table :items="reports" :fields="fields"></b-table>-->
<!--    <h3 class="mt-4">Weight Over Time</h3>-->
<!--    <div v-if="chartData && chartData.labels && chartData.labels.length > 0">-->
<!--      <line-chart :data="chartData" :options="chartOptions" />-->
<!--    </div>-->
<!--    <div v-else>-->
<!--      <p>No weight data available for the chart.</p>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--import { defineComponent } from 'vue';-->
<!--import { Line } from 'vue-chartjs';-->
<!--import {-->
<!--  Chart as ChartJS,-->
<!--  Title,-->
<!--  Tooltip,-->
<!--  Legend,-->
<!--  LineElement,-->
<!--  CategoryScale,-->
<!--  LinearScale,-->
<!--  PointElement-->
<!--} from 'chart.js';-->
<!--import { BTable } from "bootstrap-vue-3";-->

<!--ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale);-->

<!--export default defineComponent({-->
<!--  components: {-->
<!--    BTable,-->
<!--    LineChart: Line-->
<!--  },-->
<!--  created() {-->
<!--    const mockReportData = [{-->
<!--      date: '2024-08-01',-->
<!--      time: '12:00',-->
<!--      amount: 50,-->
<!--    }, {-->
<!--      date: '2024-08-01',-->
<!--      time: '13:00',-->
<!--      amount: 50,-->
<!--    }, {-->
<!--      date: '2024-08-02',-->
<!--      time: '12:00',-->
<!--      amount: 50,-->
<!--    }, {-->
<!--      date: '2024-08-02',-->
<!--      time: '13:00',-->
<!--      amount: 50,-->
<!--    }, {-->
<!--      date: '2024-08-03',-->
<!--      time: '12:00',-->
<!--      amount: 50,-->
<!--    }, {-->
<!--      date: '2024-08-03',-->
<!--      time: '14:00',-->
<!--      amount: 50,-->
<!--    }, {-->
<!--      date: '2024-08-03',-->
<!--      time: '15:00',-->
<!--      amount: 10,-->
<!--    }];-->



<!--    this.reports = mockReportData.reduce((acc, curr) => {-->
<!--      const existingReport = acc.find(r => r.date === curr.date);-->
<!--      if (existingReport) {-->
<!--        existingReport.totalAmount += curr.amount;-->
<!--        existingReport.numberOfMeals += 1;-->
<!--        existingReport.averageAmount = existingReport.totalAmount / existingReport.numberOfMeals;-->
<!--      } else {-->
<!--        acc.push({-->
<!--          date: curr.date,-->
<!--          totalAmount: curr.amount,-->
<!--          averageAmount: curr.amount,-->
<!--          numberOfMeals: 1-->
<!--        });-->
<!--      }-->
<!--      return acc;-->
<!--    }, []);-->
<!--  },-->
<!--  data() {-->
<!--    return {-->
<!--      reports: [{ date: '2024-08-01', totalAmount: 100, averageAmount: 50, numberOfMeals: 2 }],-->
<!--      fields: ['date', 'totalAmount', 'averageAmount', 'numberOfMeals'],-->
<!--      chartData: {-->
<!--        labels: ['2024-08-01', '2024-08-02', '2024-08-03'],-->
<!--        datasets: [-->
<!--          {-->
<!--            label: 'Weight',-->
<!--            data: [3.3, 3.4, 3.5],-->
<!--            fill: false,-->
<!--            borderColor: 'blue'-->
<!--          }-->
<!--        ]-->
<!--      },-->
<!--      chartOptions: {-->
<!--        responsive: true,-->
<!--        maintainAspectRatio: false-->
<!--      }-->
<!--    };-->
<!--  }-->
<!--});-->
<!--</script>-->

