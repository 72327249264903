<template>
  <w-dialog
      v-model="showValue"
      :fullscreen="dialog.fullscreen"
      :width="dialog.width"
      :persistent="dialog.persistent"
      :persistent-no-animation="dialog.persistentNoAnimation"
      :overlay-color="dialog.overlayColor"
      :overlay-opacity="dialog.overlayOpacity"
      :title-class="titleClass">
    <template #title>
      <w-icon class="mr2">{{icon}}</w-icon>
      {{ title }}
    </template>
    <slot>
    </slot>
  </w-dialog>
</template>
<script setup>
import {ref, defineProps, computed} from "vue";

const props = defineProps({
  color: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    required: true
  },
  show: {
    type: Boolean,
    required: true
  },
})

const showValue = computed(()=>(props.show))

const dialog = ref({
  fullscreen: false,
  width: '25em',
  persistent: true,
  persistentNoAnimation: false,
  overlayColor: 'rgba(0, 0, 0, 0.5)',
  overlayOpacity: 0.5
})

const titleClass = computed(() => {
  return `${props.color}--bg`
})

</script>