<template>
  <div class="profile-page">
    <h1>User Profile</h1>
    <div v-if="!isEditing">
      <p><strong>Username:</strong> {{ user.username }}</p>
      <p><strong>Email:</strong> {{ user.email }} <span v-if="user.isEmailVerified">(Verified)</span> <span v-if="user.isPrimaryEmail">(Primary)</span></p>
      <p><strong>Password:</strong> ********</p>
      <button @click="editProfile">Edit</button>
    </div>
    <div v-else>
      <p><strong>Username:</strong> {{ user.username }}</p>
      <p><strong>Email:</strong> {{ user.email }} <span v-if="user.isEmailVerified">(Verified)</span> <span v-if="user.isPrimaryEmail">(Primary)</span></p>
      <label for="newEmail">New Email:</label>
      <input type="email" v-model="newEmail" id="newEmail" />
      <label for="oldPassword">Old Password:</label>
      <input type="password" v-model="oldPassword" id="oldPassword" />
      <label for="newPassword">New Password:</label>
      <input type="password" v-model="newPassword" id="newPassword" />
      <label for="confirmNewPassword">Confirm New Password:</label>
      <input type="password" v-model="confirmNewPassword" id="confirmNewPassword" />
      <button @click="saveProfile">Save</button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const user = ref({
  username: 'john_doe',
  email: 'john@example.com',
  isEmailVerified: true,
  isPrimaryEmail: true,
});

const isEditing = ref(false);
const newEmail = ref('');
const oldPassword = ref('');
const newPassword = ref('');
const confirmNewPassword = ref('');

function editProfile() {
  isEditing.value = true;
}

function saveProfile() {
  if (newEmail.value) {
    user.value.email = newEmail.value;
    user.value.isEmailVerified = false; // New email needs verification
  }
  if (newPassword.value && newPassword.value === confirmNewPassword.value) {
    // Update password logic here
    console.log('Password updated');
  }
  isEditing.value = false;
}
</script>

<style scoped>
.profile-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.profile-page h1 {
  text-align: center;
}

.profile-page p {
  margin: 10px 0;
}

.profile-page label {
  display: block;
  margin: 10px 0 5px;
}

.profile-page input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.profile-page button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.profile-page button:hover {
  background-color: #0056b3;
}
</style>