import {defineStore} from "pinia";
import {computed, ref} from "vue";
import {useUserStore} from "@/stores/user.store";
import {GET, POST, PUT} from "@/services/base.http";

export function babyRepository() {

    async function fetch(accountId) {
        const response = await GET(`/api/baby/${accountId}`)
        if (response.ok) {
            return response.json()
        }
        throw new Error(`Baby Repository - fetch failed: ${response.statusText}`)
    }

    async function set(accountId, baby) {
        const response = await POST(`/api/baby/${accountId}`, JSON.stringify(baby))
        if (response.ok) {
            return response.json()
        }
        throw new Error(`Baby Repository - set failed: ${response.statusText}`)
    }

    async function update(accountId, baby) {
        const response = await PUT(`/api/baby/${accountId}/${baby.id}`, JSON.stringify(baby))
        if (response.ok) {
            return response.json()
        }
        throw new Error(`Baby Repository - update failed: ${response.statusText}`)
    }



    return {
        fetch,
        set,
        update
    }
}

const repository =  babyRepository()

export const useBabyStore = defineStore('baby', ()=> {
    const data = ref({})
    const loading = ref(false)
    const userStore = useUserStore()

    async function load() {
        try {
            loading.value = true
            const accountId = userStore?.user?.accountId;
            const response = await repository.fetch(accountId)
            data.value = {
                id: response.id,
                name: response.name
            }
        } catch (error) {
            console.log("baby load", error)
        } finally {
            loading.value = false
        }
    }

    async function update(baby) {
        try {
            loading.value = true
            const accountId = userStore?.user?.accountId;
            const response = await repository.update(accountId, baby)
            data.value = {
                id: response.id,
                name: response.name
            }
        } catch (error) {
            console.log("baby update", error)
        } finally {
            loading.value = false
        }
    }

    async function create(baby) {
        try {
            loading.value = true
            const accountId = userStore?.user?.accountId;
            const response = await repository.set(accountId, baby)
            data.value = {
                id: response.id,
                name: response.name
            }
        } catch (error) {
            console.log("baby set", error)
        } finally {
            loading.value = false
        }
    }

    const babyWasCreated = computed(() => {
        if(data.value.id === undefined){
            return true
        }
        return data.value.id !== 0;
    })

    const name = computed(() => {
        return data.value?.name
    })

    return {
        data,
        loading,
        load,
        update,
        create,
        name,
        babyWasCreated
    }
})